<!-- Shubham,6-feb-2024,Moved the images inside the span -->
<!-- <template>
  <span v-if="text && url">
    <a v-if="isExternal" :href="url" class="usa-link" target="_blank"
      >{{ text }}
      <span class="external-img"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/external-link.svg') + ')',
        }"
      ></span>
      <span
        class="hover external-img"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/external-link-hover.svg') + ')',
        }"
      ></span>
    </a>
    <a v-if="!isExternal" :href="url" class="usa-link">{{ text }}</a>
  </span>
</template> -->
<template>
  <span v-if="text && url">
    <a v-if="isExternal" :href="url" class="usa-link" target="_blank">{{text}}
      <span class="external-img">
        <!-- <img src="@/assets/img/external-link-alt-hover.svg"> -->
       </span>
      <span class="hover external-img hovered-external-img">
       <!-- <img src="@/assets/img/external-link-alt.svg"> -->
       </span>
    </a>
    <a v-if="!isExternal" :href="url" class="usa-link">{{ text }}</a>
  </span>
</template>

<script>
import { HOST_NAME } from "@/config";
import qs from "query-string";
import { urlCountryCode, urlLangCode } from "@/helpers/url";
import { getLanguageUrlLabels, getCountryName } from "@/store/api";

function hasVar(str) {
  return str.includes("{{");
}

function getUrlScope() {
  return Promise.all([
    getCountryName(urlCountryCode()),
    getLanguageUrlLabels(),
  ]).then(([country, languageUrlLabels]) => {
    const fullLanguage = languageUrlLabels[urlLangCode()];
    return {
      country: country,
      language: fullLanguage,
      lang: fullLanguage,
    };
  });
}

function getVarName(str) {
  return str
    .replace(new RegExp("{", "g"), "")
    .replace(new RegExp("}", "g"), "")
    .trim();
}

async function replaceVariables(url) {
  // example url: https://cgifederal.secure.force.com/?language={{lang}}&country={{country}}
  if (!hasVar(url)) return url;
  const parseUrl = new URL(url);
  const queryObj = qs.parse(parseUrl.search);
  const scope = await getUrlScope();
  Object.keys(queryObj).forEach((key) => {
    if (hasVar(queryObj[key])) {
      const varName = getVarName(queryObj[key]);
      const value = scope[varName];
      queryObj[key] = value;
    }
  });
  parseUrl.search = qs.stringify(queryObj);
  return parseUrl.toString();
}

function isExternal(url) {
  if (!isValidUrl(url)) {
    return false;
  }
  const urlObj = new URL(url);
  return urlObj.host !== HOST_NAME;
}

function isValidUrl(url) {
  return (
    url.startsWith("http") ||
    url.startsWith("mailto") ||
    url.startsWith("skype")
  );
}

export default {
  name: "link-component",
  asyncComputed: {
    url: async function () {
      if (this.$vnode.data.url == "https://ustraveldocs.com/index.html") {
        return this.$vnode.data.url;
      }
      if (isExternal(this.$vnode.data.url)) {
        return replaceVariables(this.$vnode.data.url);
      }
      if (!this.isValidUrl) {
        let pathPage = this.$vnode.data.url
          .replace("/in", "")
          .replace(".html", "");
        return pathPage;
      }

      const urlObj = new URL(this.$vnode.data.url);
      if (urlObj.host === HOST_NAME) {
        const path = urlObj.pathname.replace("/in", "").replace(".html", "");
        return `${path}${urlObj.hash}`;
      }
    },
  },
  computed: {
    text: function () {
      return this.$vnode.data.text;
    },
    isExternal: function () {
      return isExternal(this.$vnode.data.url);
    },
  },
};
</script>

<style lang="scss" scoped>
a span {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  &.hover {
    display: none;
  }
}
a:hover {
  span {
    display: none;
  }
  span.hover {
    display: inline-block;
  }
}
.transparent-body-white-border {
  .usa-link {
    font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial,
      sans-serif;
    font-size: 1.06471rem;
    line-height: 0.93923;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 0.25rem;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: 0.5rem;
    padding: 0.75rem 1.25rem;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: subpixel-antialiased;
    background-color: transparent;
    width: auto;
    -webkit-box-shadow: inset 0 0 0 2px #dcdee0;
    box-shadow: inset 0 0 0 2px #dcdee0;
    color: #dcdee0;
    &:hover {
      text-decoration: none;
    }
  }
}
.top-margin-extra {
  .usa-link {
    margin-top: 2rem;
  }
}

.respButton {
  .usa-link {
    display: none;
  }
}
.bg-primary-dark .external-img{
  background-image: url('../assets/img/external-link-alt-hover.svg');
}

.external-img{
  background-image: url('../assets/img/external-link.svg');
}

.bg-primary-dark .hovered-external-img{
  background-image: url('../assets/img/external-link-alt.svg');
}

.hovered-external-img{
  background-image: url('../assets/img/external-link-hover.svg');
}

.cgi-inline-notice .external-img{
  background-image: url('../assets/img/external-link-alt-hover.svg');
}

.cgi-inline-notice .hovered-external-img{
  background-image: url('../assets/img/external-link-hover.svg');
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .respButton {
    .usa-link {
      width: 92%;
      margin-left: 4%;
      font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial,
        sans-serif;
      font-size: 1.06471rem;
      line-height: 0.93923;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 0;
      border-radius: 0.25rem;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      margin-right: 0.5rem;
      padding: 0.75rem 1.25rem;
      text-align: center;
      text-decoration: none;
      -webkit-font-smoothing: subpixel-antialiased;
      background-color: transparent;
      -webkit-box-shadow: inset 0 0 0 2px #dcdee0;
      box-shadow: inset 0 0 0 2px #dcdee0;
      color: #dcdee0;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .transparent-body-white-border {
    .usa-link {
      display: none;
    }
  }
}
</style>