<template>
  <div class="exhange-container">
    <div class="bg-accent-cool-lighter text-center padding-1">
      <div class="">{{translationKeywords['ex-rate'] || 'Exchange rate'}}</div>
      <div class="grid-row flex-justify-center">
        <div class="grid-col-12 tablet:grid-col-6">
          {{translationKeywords['current-consular-exchange-rate'] || 'Current consular exchange rate'}}: <span class="data-exchange-rate" v-if="exchangeRate && currency"><span data-exchange-rate="1" data-exchange-rate-updated="true" >{{ exchangeRate }}</span> {{ currency }} = 1 USD</span>
          <span v-if="!exchangeRate || !currency">No data found</span>
        </div>
        <div class="grid-col-12 tablet:grid-col-6">
          {{translationKeywords['current-rate-valid-through'] || 'Current rate valid through'}}: <span data-exchange-rate-date="" data-exchange-rate-date-updated="true" v-if="expiryDate">{{ expiryDate }}</span>
          <span v-if="!expiryDate">No data found</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getExchangeRate, getExchangeRateExpiry, getCurrencyCode, getTranslationKeywords } from "@/store/api";
import { urlCountryCode,urlLangCode } from "@/helpers/url";

export default {
  name: "exchange-rate-info-section",
  props: ["data"],
  data(){
    return{
      exchangeRate :  '',
      currency: '',
      expiryDate: "",
      currency: '',
      translationKeywords: {},
    }
  },
  created() {
    const countryCode = urlCountryCode();
    getCurrencyCode(countryCode).then(currency => this.currency = currency);
    getExchangeRate(countryCode).then(exchangeRate => this.exchangeRate = exchangeRate);
    getExchangeRateExpiry(countryCode).then(expiryDate => this.expiryDate = expiryDate);
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords = keywords)
    );
  },
};
</script>
<style lang="scss" scoped>
  .exhange-container {
    margin: 20px 0 10px;
  }
</style>
